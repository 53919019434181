
import { defineComponent, onMounted, ref } from "vue";
import useBiaForm from "@/forms/useBiaForm";
import IdIcon from "@/components/icon/IdIcon.vue";
import formHttp from "@/http/formHttp";
import { useRouter } from "vue-router";
import { useLoader } from "@/use/useLoader";

export default defineComponent({
  components: { IdIcon },
  setup() {
    const router = useRouter();
    const { v$, fields } = useBiaForm();
    const showAlert = ref<boolean>(false);
    const { isLoading, addLoading, removeLoading } = useLoader();

    onMounted(async () => {
      try {
        const { data } = await formHttp.getStep1();
        Object.assign(fields, data.content);
      } catch ({ response }) {
        showAlert.value = true;
      }
    });

    const onSubmit = async () => {
      v$.value.$touch();

      if (v$.value.$invalid) return;

      try {
        addLoading();
        await formHttp.postBia(fields);
        await formHttp.postStep3();
        localStorage.setItem("status", "confirmed");
        router.push({ name: "ComplementConfirmed" });
      } catch ({ response }) {
        showAlert.value = true;
      }

      removeLoading();
    };

    const onCaptchaVerified = (recaptchaToken: string) => {
      fields.recaptcha = recaptchaToken;
    };

    const onCaptchaExpired = () => {
      fields.recaptcha = "";
    };

    return {
      v$,
      fields,
      onSubmit,
      showAlert,
      onCaptchaVerified,
      onCaptchaExpired,
      isLoading,
    };
  },
});
